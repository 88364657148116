<template>
  <div ref="viewerContainer" class="demo-viewer">
  <vc-config-provider :locale="locale" cesiumPath="https://unpkg.com/cesium@latest/Build/Cesium/Cesium.js">
    <vc-viewer :info-box="false" sceneModePicker shouldAnimate ref="vcViewer" :skeleton="true"  :showCredit="false" :vrButton="true" @moveEnd="moveEnd" @moveStart="moveStart" @ready="onViewerReady">
      <vc-navigation ref="navigation" position="bottom-right" :otherOpts="false" :printOpts="false" :compassOpts="false" :offset="[20, 55]"></vc-navigation>
      <vc-layer-imagery :sort-order="20">
        <vc-imagery-provider-tianditu map-style="cva_c" token="436ce7e50d27eede2f2929307e6b33c0"></vc-imagery-provider-tianditu>
      </vc-layer-imagery>
      <vc-layer-imagery :sort-order="10">
        <vc-imagery-provider-tianditu map-style="img_c" token="436ce7e50d27eede2f2929307e6b33c0" ref="provider"></vc-imagery-provider-tianditu>
      </vc-layer-imagery>
      <vc-datasource-geojson
        ref="datasourceRef"
        data="https://zouyaoji.top/vue-cesium/SampleData/geojson/china.json"
        @ready="onDatasourceReady"
        :show="true"
        stroke="red"
        @click="onClicked"
        :entities="entities"
      ></vc-datasource-geojson>
      <vc-datasource-czml
      ref="datasourceRef"
      czml="https://zouyaoji.top/vue-cesium/SampleData/simple.czml"
      @ready="onDatasourceReady"
      :show="show"
      @click="onClicked"
    ></vc-datasource-czml>
    <vc-datasource-czml :czml="czml"></vc-datasource-czml>
    </vc-viewer>
  </vc-config-provider>
  <!-- <div class="demo-toolbar">
    <el-button type="danger" round @click="toggleLanguage">切换语言</el-button>
  </div> -->
  <!-- <div style="position:fixed;top:10px;left:10px;">
    <button @click="addGeoJson">加载国家数据</button>
  </div> -->
</div>
</template>
<script>
  import {VcConfigProvider,VcViewer,VcNavigation,VcLayerImagery,VcImageryProviderTianditu,VcDatasourceGeojson,VcDatasourceCzml} from 'vue-cesium'
  import CommonService from '../../services/CommonService'
  import config from '../../config'
  import 'vue-cesium/dist/index.css'
  // import enUS from 'vue-cesium/es/locale/lang/en-us'
  const locale1 = {
    name: 'en-us',
    nativeName: 'English (US)',
    vc: {
      loadError: 'needs to be child of VcViewer',
      navigation: {
        compass: {
          outerTip: 'Drag outer ring: rotate view. Double-click: reset view.',
          innerTip: 'Drag inner gyroscope: free orbit. TIP: You can also free orbit by holding the CTRL key and dragging the map.',
          title: 'Click and drag to rotate the camera.'
        },
        zoomCotrol: {
          zoomInTip: 'Zoom in',
          zoomResetTip: 'Reset zoom',
          zoomOutTip: 'Zoom out'
        },
        print: {
          printTip: 'Viewer screenshot/print',
          printViewTitle: 'VueCesium Print View',
          credit: 'Map Credits',
          screenshot: 'Screenshot'
        },
        myLocation: {
          myLocationTip: 'Centre map at your current location',
          positioning: 'Positioning...',
          fail: 'Positioning failed',
          centreMap: 'My Location',
          lat: 'Lat',
          lng: 'Lng',
          address: 'Address'
        },
        statusBar: {
          lat: 'Lat',
          lng: 'Lng',
          zone: 'ZONE',
          e: 'E',
          n: 'N',
          elev: 'Elev',
          level: 'Level',
          heading: 'H',
          pitch: 'P',
          roll: 'R',
          cameraHeight: 'CameraH',
          tip: 'Click to switch the mouse display coordinates to UTM projection coordinates'
        }
      }
    }
  }
  const locale2 = {
    name: 'zh-hans',
    nativeName: '中文(简体)',
    vc: {
      loadError: '加载失败，必须作为 VcViewer 的子组件加载。',
      navigation: {
        compass: {
          outerTip: '旋转视图：顺/逆时针方向拖拽罗盘外环。\n重置视图：双击罗盘外环。',
          innerTip: '翻转视图：由内环向外环拖拽罗盘。\n 或者按住 Ctrl 键的同时拖拽地图。',
          title: '按住鼠标拖拽旋转相机。'
        },
        zoomCotrol: {
          zoomInTip: '放大',
          zoomResetTip: '重置视图',
          zoomOutTip: '缩小'
        },
        print: {
          printTip: '场景截图/打印',
          printViewTitle: '打印预览',
          credit: '地图版权',
          screenshot: '场景截图'
        },
        myLocation: {
          myLocationTip: '定位您的位置',
          positioning: '定位中...',
          fail: '定位失败',
          centreMap: '我的位置',
          lat: '纬度',
          lng: '经度',
          address: '地址'
        },
        statusBar: {
          lat: '纬度',
          lng: '经度',
          zone: '带号',
          e: 'X',
          n: 'Y',
          elev: '高程',
          level: '层级',
          heading: '方位',
          pitch: '俯仰',
          roll: '侧翻',
          cameraHeight: '视高',
          tip: '点击切换鼠标显示坐标为 UTM 投影坐标'
        }
      }
    }
  }
  export default {
    components: {
      VcConfigProvider,
      VcViewer,
      VcNavigation,
      VcLayerImagery,
      VcImageryProviderTianditu,
      VcDatasourceGeojson,
      VcDatasourceCzml
    },
    data() {
      const entities =[]
      for (let i = 0; i < 10; i++) {
        // entities.push({
        //   position: [Math.random() * 40 + 85, Math.random() * 30 + 21],
        //   label: {
        //     text: i.toString(),
        //     pixelOffset: { x: 25, y: 20 }
        //   },
        //   point: {
        //     pixelSize: 8,
        //     outlineWidth: 2,
        //     color: 'red',
        //     outlineColor: 'yellow'
        //   }
        // })
      }
      const czml = [
      ]
      return {
        // locale: enUS
        locale: locale1,
        startlng:0,
        endlng:0,
        viewer:null,
        deviceID:'0',//设备的唯一ID
        userDeviceID:'0',//跟用户绑定之后的ID
        entities,
        czml
      }
    },
    methods: {
      onDatasourceReady({ Cesium, viewer, cesiumObject }){
        // alert(1);
        // viewer.zoomTo(cesiumObject)
      },
      onClicked(e){
        console.log(e.pickedFeature.id.name);
        if(e.pickedFeature.id.name){
          this.tts(e.pickedFeature.id.name);
          if(this.deviceID!='0'){
            CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'speech',Extra:e.pickedFeature.id.name}});
          }
        }
      },
      moveStart(a){
        var pos=this.getCenterPosition();
        this.startlng=pos.lng;
      },
      moveEnd(a){
         var pos=this.getCenterPosition();
         this.endlng=pos.lng;
         var angle=(this.endlng-this.startlng).toFixed(0);
         CommonService.SendMessage(this.deviceID,{Topic:'',Payload:{Action:'angle',Extra:angle}});
      },
      toggleLanguage() {
        if (this.locale.name === 'zh-hans') {
          this.locale = locale1
        } else {
          this.locale = locale2
        }
      },
      onViewerReady({ Cesium, viewer, vm }){
        console.log('viewer is loaded.', vm)
        vm.vcMitt.on('destroyed', e => {
          console.log('viewer is destroyed', e)
        })
        // viewer.scene.globe.enableLighting = true
        this.viewer=viewer
        this.cesium=Cesium
        //解决安卓上不显示球的bug
        this.viewer.scene.globe.showGroundAtmosphere = false;
        debugger
        // this.viewer.infoBox=null;
      },
      getCenterPosition(){
        if(!this.viewer) return{lng:0,lat:0,pitch:0}
        var result = this.viewer.camera.pickEllipsoid(
          new Cesium.Cartesian2(
            this.viewer.canvas.clientWidth / 2,
            this.viewer.canvas.clientHeight / 2,
          ),
        );
        var curPosition = Cesium.Ellipsoid.WGS84.cartesianToCartographic(result);
        var lon = (curPosition.longitude * 180) / Math.PI;
        var lat = (curPosition.latitude * 180) / Math.PI;
        var pitch = Number((this.viewer.camera.pitch).toFixed(2));
        // var angle = new Array();
        // angle.push(lon);  // 经度
        // angle.push(lat);  // 纬度
        // angle.push(pitch); // 俯仰角
        return {lng:lon,lat:lat,pitch:pitch};
      },
      tts(speechText){
          fetch(config.apiHost + '/api/gpt/tts/', {
              method: 'POST',
              headers: {
                  'Access-Token':''
              },
              mode: 'cors',
              credentials: 'include',
              body: JSON.stringify({text:speechText}),
          })
          .then(response => response.json())
          .then(json=>{
              if(json.Success){
                  var base64String=json.Data;
                  // 创建一个新的 Audio 对象
                  var audio = new Audio();
                  // 将 base64 字符串设置为音频源
                  audio.src = "data:audio/wav;base64,"+base64String;
                  // 播放音频
                  audio.play();
                  // 可选：处理播放错误
                  audio.onerror = function() {
                      console.error("音频播放失败");
                  };  
              }
          })
          .catch(e => {
              console.log(e);
          });
      },
    },
    mounted: function () {
      // setInterval(() => {
      //    if(this.delayClose.isEnable){
      //      this.delayClose={...this.delayClose};
      //    }
      // }, 1000);
      if(!this.$route.query.token){
          console.log('invalid token');
          return;
      }
      if(!this.$route.query.id){
        console.log('invalid deviceid');
        return;
      }
      // if(this.$route.query.locale){
      //     // alert(this.$route.query.locale);
      //     this.$i18n.locale =this.$route.query.locale;
      // }
      //设置token
      this.token=this.$route.query.token;
      // // 该ID为设备与用户绑定之后的ID,并不是设备ID
      this.userDeviceID=this.$route.query.id;
      // alert(this.userDeviceID)
      // this.token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6IjYiLCJOYW1lIjoi546L5q2j5p2wIiwiZXhwIjoxNjgzODE2MDc5LCJpc3MiOiJhcGkuYXNzdGFyLmNvbSIsImF1ZCI6ImFzc3RhciJ9.wrnyeHdCFriFdPxsr9c4G4JdoVU8t_dRfmGJBxZ4HIk';
      // this.userDeviceID='1427158576282996736';
      this.$store.dispatch('user/setToken',this.token);
      //获取设备状态信息
      CommonService.GetDeviceStatus(this.userDeviceID).then(s=>{
        if(s.Success){
           if(s.Data){
              //返回格式{DeviceID:0,IsOnline:1,Status:''}
              this.deviceID=s.Data.DeviceID;
              this.isOnling=s.Data.IsOnline;
              if(!s.Data.IsOnline){
                // alert('设备已离线');
                console.log('设备已离线');
                return;
              }
           }
        }
        else{
          // alert();
          console.log(s.Message);
        }
      });
    }
  }
</script>
<style lang="scss" scoped>
.demo-viewer{
  height: 100vh;
  position: relative;
  .demo-toolbar{
    position:absolute;
    bottom: 0;
    background: #fff;
    border-radius: 5px;
  }
}
</style>